import React from 'react';

export const Title = () => (
  <>
    <small className="order-1 text-lg">
      Latitud 57° 53’ 15” Longitud 11° 35’ 37”
    </small>
    <h1 className="text-5xl md:text-7xl leading-none">Marstrands Optik</h1>
  </>
);
