import React, { FunctionComponent } from 'react';
import { Cover } from '../cover';
import { GoogleOpenHours } from '../google-open-hours/google-open-hours';
import { InstagramImages } from '../instagram-images/instagram-images';
import { Title } from '../title';
import staff from './emma-susanne.png';
import woman from './photo-of-woman-wearing-sunglasses.jpg';

export const App = () => (
  <>
    <div className="max-w-screen-lg mx-auto lg:p-16 xl:p-20">
      <Cover alt="Marstrand Optik omslagsbild" src={woman}>
        <Title />
      </Cover>
    </div>
    <section className="max-w-screen-lg mx-auto py-12 px-5 sm:px-16 md:px-12 lg:px-16 xl:px-20 flex justify-between">
      <div className="md:max-w-3xl">
        <H2>Välkommen till oss på Marstrands Optik!</H2>
        <P>
          Vi hjälper dig med undersökning och val av glasögon. Vi är din
          personliga optiker. Hela vägen, det lovar vi!
        </P>
        <P>
          Du är varmt välkommen att ringa oss på{' '}
          <a href="tel:030361655">0303 - 61 655</a> eller klicka på knappen
          nedan för att boka tid. ♥
        </P>

        <a
          className="inline-block"
          href="https://www.bokadirekt.se/places/marstrands-optik-ab-42700"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://foretag.bokadirekt.se/bokatid/BokaTid_Gron_MorkBakgrund_120px.png"
            alt="Boka tid hos Boka direkt"
          />
        </a>
      </div>
      <div className="hidden p-10 md:block w-1/2">
        <img
          className="mx-auto"
          src={staff}
          alt="Emma och Susanne bakom disk"
        />
      </div>
    </section>

    <div className="bg-gray-100">
      <section className="max-w-screen-lg mx-auto py-12 px-5 flex justify-between flex-col items-center md:flex-row sm:px-16 md:px-12 lg:px-16 xl:px-20">
        <div className="w-full md:w-1/2">
          <H2>Öppetider</H2>
          <P>
            Hur roligt det än må vara att hjälpa dig hitta de perfekta bågarna
            behöver även vi ta en paus ibland. Här ser du våra öppetider.
          </P>
        </div>

        <div className="w-full md:w-1/2">
          {/* <ClosedDate>Tillfälligt stängt pga sjukdom denna vecka.</ClosedDate> */}
          {/* <HolidayOpenHours /> */}
          <GoogleOpenHours />
        </div>
      </section>
    </div>

    <InstagramSection className="max-w-screen-lg mx-auto py-12 px-5 sm:px-16 md:px-12 lg:px-16 xl:px-20">
      <H2>#marstrandsoptik</H2>
      <P>
        Det är alltid lika roligt när våra kunder visar att de är nöjda. Ni får
        gärna tagga era bilder med{' '}
        <a
          href="https://www.instagram.com/explore/tags/marstrandsoptik/"
          className="underline font-bold"
        >
          #marstrandsoptik
        </a>{' '}
        på instagram.
      </P>
    </InstagramSection>
  </>
);

export const ClosedDate: React.FC = ({ children }) => (
  <p className="text-center p-6 text-red-600">{children}</p>
);

export const InstagramSection: React.FC<
  React.HtmlHTMLAttributes<HTMLElement>
> = ({ children, ...rest }) => (
  <section {...rest}>
    <div className="md:max-w-3xl">{children}</div>
    <InstagramImages />
  </section>
);

export const H2: FunctionComponent = ({ children, ...rest }) => (
  <h2 className="text-mo-primary mb-5" {...rest}>
    {children}
  </h2>
);

export const P: FunctionComponent = ({ children, ...rest }) => (
  <p className="mb-5" {...rest}>
    {children}
  </p>
);
