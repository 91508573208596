import React, { FunctionComponent } from 'react';

interface CoverProps {
  src: string;
  alt: string;
}

export const Cover: FunctionComponent<CoverProps> = ({
  alt,
  src,
  children,
  ...rest
}) => (
  <div className="relative bg-mo-primary md:flex md:flex-row-reverse">
    <img
      className="opacity-95 object-cover md:w-1/2"
      alt={alt}
      src={src}
      {...rest}
    />
    <div className="absolute top-0 bottom-0 right-0 left-0 md:relative md:w-1/2">
      <div className="flex flex-col-reverse h-full p-5 pb-12 sm:p-20 sm:pb-24 md:p-12 md:pb-24 lg:pb-48 text-white">
        {children}
      </div>
    </div>
  </div>
);
