import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IGResponse } from '../../entities/IGResponse';

export const InstagramImages: React.FunctionComponent = () => {
  const [images, setImages] = useState<IGResponse[]>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    axios
      .get<IGResponse[]>('/api/get-instagram-images')
      .then(({ status, data }) => {
        if (status !== 200) {
          console.error('Could not fetch instagram images.');
          return setError(true);
        }

        setImages(data);
      })
      .catch((error) => {
        console.error('Could not fetch instagram images.', error);
        return setError(true);
      });
  }, []);

  if (error) return <Error />;
  if (!images) return <Loader className="h-48 md:h-80" />;
  return <Images images={images} />;
};

const Images: React.FC<{ images: IGResponse[] }> = ({ images }) => (
  <div className="flex space-x-6 md:space-x-10 overflow-auto scrollbar-hide">
    {images.map((i) => (
      <a key={i.id} href={i.permalink}>
        <img
          className="h-48 max-w-max md:h-80"
          src={i.thumbnail_url || i.media_url}
          alt={i.caption}
        />
      </a>
    ))}
  </div>
);

const Error: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({
  ...rest
}) => (
  <div
    className="h-48 justify-center flex items-center flex-col space-y-2"
    {...rest}
  >
    <em>Typiskt! Lyckades inte hämta bilder från instagram 😞.</em>
    <em>
      Vilken tur att du kan se dem om du{' '}
      <a
        className="underline font-bold"
        href="https://www.instagram.com/marstrands_optik/"
      >
        klickar här.
      </a>
    </em>
  </div>
);

const Loader: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}) => <div className={`animate-pulse bg-gray-100 ${className}`} {...rest} />;
